export const numberStatusToString: (status: number) => string = (status) => {
  switch (status) {
    case 1:
      return 'NEW';
    case 2:
      return 'IN_BLOCK_OK';
    case 3:
      return 'IN_BLOCK_ERR';
    case 4:
      return 'FINALIZED_OK';
    case 5:
      return 'FINALIZED_ERR';
    case 6:
      return 'UNKNOWN_ERR';
    default:
      return '';
  }
}
