import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { BankDetails } from './pages/details';
import { Banks } from './pages/banks';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Navigate to={'/services/rtgs'} />} />
        <Route path="/services/rtgs" element={<Banks />} />
        <Route path="/services/rtgs/details/:id?/:name?" element={<BankDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
