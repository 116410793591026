import { useState, useEffect } from 'react';
import { login, verifyToken } from '../../utils/http';
import { useAuthContext } from '../../context/auth-context';

const useLogin = <Props>(): { isAuthorized: boolean; loading: boolean } => {
  const { isAuthorized, setIsAuthorized } = useAuthContext();

  const [loading, setLoading] = useState<boolean>(true);
  // const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const verify = await verifyToken();

        if (verify) {
          setIsAuthorized && setIsAuthorized(true);
        } else {
          const result = await login();
          setIsAuthorized && setIsAuthorized(result.isAuthorized);
        }

        setLoading(false);
      } catch (error: any) {
        console.error('Failed to fetch data:', error);
        // setError(error);
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      setIsAuthorized && setIsAuthorized(false);
    };
  }, []);

  return { isAuthorized, loading };
};

export default useLogin;
