import React from 'react';
import { AuthLayout } from '../../new-components/layouts/auth-layout';
import s from './index.module.scss';
import { BankList } from './bank-list';
import MessageList, { Message } from '../../new-components/common';

interface BankInfo {
  bank: string;
  reserve?: string;
  digital?: string;
  name: string;
}

const testString =
  '{"status":"NEW","transactionId":"73ba4512-36ea-49b2-b787-40b8ae68269d","caller":"5GrwvaEF5zXb26Fz9rcQpDWS57CtERHpNehXCPcNoHGKutQY","callerEmail":"admin@inx.co","callerBank":"Central Bank","transactionType":"mint","transactionArgs":{"bank":"VladBank","walletName":"Main","addressIndex":0,"assetId":0,"amount":"234"},"events":[]}\n';

export const Banks: React.FC = () => {
  const fake: Message[] = [
    { isExpanded: false, content: testString, id: 0 },
    {
      isExpanded: true,
      content: testString,
      id: 1,
    },
    { isExpanded: true, content: testString, id: 2 },
    { isExpanded: true, content: testString, id: 3 },
    {
      isExpanded: true,
      content: testString,
      id: 4,
    },
    { isExpanded: true, content: testString, id: 5 },
    { isExpanded: true, content: testString, id: 6 },
  ];
  return (
    <AuthLayout>
      <div className={s.top}>
        <BankList />
      </div>
      <div className={s.bottom}>
        <MessageList initialMessages={[]} />
      </div>
    </AuthLayout>
  );
};
