import { useAuthContext } from '../../../context/auth-context';
import React, { useEffect, useState } from 'react';
import { BankInfo, BankResponse } from '../../../new-components/interfaces';
import { getBanks } from '../../../utils/http';
import { fetchAccountId, fetchRAccountId } from '../../../utils/functions';
import { BankItem } from '../bank-item';
import s from './index.module.scss';

export const BankList: React.FC = () => {
  const { isAuthorized } = useAuthContext();

  const [availableBanks, setAvailableBanks] = useState<BankResponse[]>([]);
  const [bankData, setBankData] = useState<BankInfo[]>([]);

  useEffect(() => {
    if (!isAuthorized) return;
    const getBanksData = async () => {
      const banks = await getBanks();
      setAvailableBanks(banks);
    };

    getBanksData();
  }, [isAuthorized]);

  useEffect(() => {
    console.log('Bank Addresses Data', bankData);
  }, [bankData]);

  // const temp = [{name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "PrivateBank"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "ZhursBankRenamed"},
  //     {name: "sddfgf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sx zxd"},
  //     {name: "sdfgdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sdt ret "},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sder "},
  //     {name: "sddff", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd er er"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sd fgdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sf df", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"},
  //     {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"}, {name: "sdf", digital: "sdf", reserve: "sdf", id: "dsf", bank: "sd"}]

  useEffect(() => {
    let active = true;
    setBankData([]);

    async function getAddresses(bankId: string, bankName: string) {
      if (isAuthorized && bankId) {
        if (!active) return;
        const reserveFundsAddress = await fetchRAccountId(bankId);
        const digitalFundsAddress = await fetchAccountId(bankId, 'Main', 'main');

        return {
          id: bankId,
          bank: bankName,
          reserve: reserveFundsAddress,
          digital: digitalFundsAddress,
        };
      }
      return null;
    }

    const fetchData = async () => {
      const allBankDataPromises = availableBanks?.map((bank) => getAddresses(bank.id, bank.name));
      const allBankData = await Promise.all(allBankDataPromises);
      console.log('allBankData', allBankData);
      setBankData((prev) => [
        ...prev,
        ...(allBankData?.filter((x) => x?.bank !== 'Central Bank' && x?.digital) as BankInfo[]),
      ]);
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [availableBanks]);

  return (
    <div className={s.root}>
      <div className={s.content}>
        {bankData.map((bank) => (
          <BankItem key={bank.id} {...bank} />
        ))}
      </div>
    </div>
  );
};
