import React, { PropsWithChildren, useCallback, useEffect, useReducer, useState } from 'react';
import { defaultSocketContextState, SocketContextProvider, SocketReducer } from './socket-context';
import { useSocket } from '../hooks/useSocket';
import { notification } from 'antd';

const path = '/services/transaction-update/socket.io';

export interface Props extends PropsWithChildren {
  isAuthorized: boolean;
}

export const SocketContextComponent: React.FC<Props> = ({ children, isAuthorized }) => {
  const [SocketState, SocketDispatch] = useReducer(SocketReducer, defaultSocketContextState);
  const [loading, setLoading] = useState(true);

  const socket = useSocket(process.env.REACT_APP_WS_URL!, {
    reconnectionAttempts: 5,
    reconnectionDelay: 5000,
    autoConnect: false,
    path,
  });

  useEffect(() => {
    // Connect to socket
    socket.connect();

    // Save socket to context
    SocketDispatch({ type: 'update_socket', payload: socket });

    // Start listeners
    startListeners();

    // Send the handshake
    sendHandshake();
  }, []);

  const sendHandshake = useCallback(() => {
    if (isAuthorized) {
      console.log('Handshake');
      socket.emit('subscribe-all-banks', '');
      setLoading(false);
      console.log('Connected To Socket');
    }
  }, [socket, isAuthorized]);

  const startListeners = useCallback(() => {
    socket.io.on('reconnect', (attempt) => {
      sendHandshake();
    });

    // socket.io.on("reconnect_attempt", (attempt) => {});

    // socket.io.on("reconnect_error", (error) => { });

    socket.io.on('reconnect_failed', () => {
      notification.error({
        message: 'WebSocket reconnect failed',
        description: 'Failed to re-establish connection to Nabatech services.',
        duration: 3,
        pauseOnHover: true,
        role: 'status',
      });
    });

    socket.on('disconnect', () => {
      console.info('Disconnected->');
    });

    socket.on('transaction-update', (data: any) => {
      console.log('Data', data);
      SocketDispatch({ type: 'add_message', payload: data });
    });
  }, [socket, sendHandshake]);

  if (loading) return <p>loading sockets...</p>;
  return <SocketContextProvider value={{ SocketState, SocketDispatch }}>{children}</SocketContextProvider>;
};
