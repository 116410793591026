import { Socket } from 'socket.io-client';
import React, { createContext, useContext } from 'react';

export interface SocketContextState {
  socket: Socket | undefined;
  messages: string[] | undefined;
  lastMessage?: string | undefined;
}

const testString =
  '{"status":"NEW","transactionId":"73ba4512-36ea-49b2-b787-40b8ae68269d","caller":"5GrwvaEF5zXb26Fz9rcQpDWS57CtERHpNehXCPcNoHGKutQY","callerEmail":"admin@inx.co","callerBank":"Central Bank","transactionType":"mint","transactionArgs":{"bank":"VladBank","walletName":"Main","addressIndex":0,"assetId":0,"amount":"234"},"events":[]}\n';

export const defaultSocketContextState: SocketContextState = {
  socket: undefined,
  // messages: []
  messages: [testString],
  lastMessage: '',
};

export type TSocketContextActions = 'update_socket' | 'add_message';

export type SocketContextPayload = string | Socket;

export interface SocketContextActions {
  type: TSocketContextActions;
  payload: SocketContextPayload;
}

export const SocketReducer = (state: SocketContextState, action: SocketContextActions) => {
  console.log('message received', action);

  switch (action.type) {
    case 'update_socket':
      return { ...state, socket: action.payload as Socket };
    case 'add_message':
      return {
        ...state,
        lastMessage: action.payload as string,
        messages: [...(state.messages || []), action.payload as string],
      };
    default:
      return { ...state };
  }
};

export interface SocketContextProps {
  SocketState: SocketContextState;
  SocketDispatch: React.Dispatch<SocketContextActions>;
}

const SocketContext = createContext<SocketContextProps>({
  SocketState: defaultSocketContextState,
  SocketDispatch: () => {},
});

export const SocketContextProvider = SocketContext.Provider;

export default SocketContext;

export const useSocketContext = () => useContext(SocketContext);
