import React, { useEffect, useState } from 'react';
import { AuthLayout } from '../../new-components/layouts/auth-layout';

import s from '../banks/index.module.scss';
import MessageList, { Message } from '../../new-components/common';
import { useParams } from 'react-router-dom';
import { AccountSelector } from '../../components/selector/account-selector.component';

import { useSocketContext } from '../../context/socket-context';
import { BalanceDetails } from './balances';

const testString =
  '{"status":"NEW","transactionId":"73ba4512-36ea-49b2-b787-40b8ae68269d","caller":"5GrwvaEF5zXb26Fz9rcQpDWS57CtERHpNehXCPcNoHGKutQY","callerEmail":"admin@inx.co","callerBank":"Central Bank","transactionType":"mint","transactionArgs":{"bank":"VladBank","walletName":"Main","addressIndex":0,"assetId":0,"amount":"234"},"events":[]}\n';

export const BankDetails: React.FC = () => {
  const { id, name } = useParams();
  const {
    SocketState: { lastMessage },
  } = useSocketContext();

  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentName, setCurrentName] = useState<string | undefined>(undefined);
  const [selectedBank, setSelectedBank] = useState<{
    id: string;
    value: string;
  }>();

  useEffect(() => {
    if (!id || !name) return;
    setCurrentName(name);
    setCurrentId(id);
  }, [id, name]);

  useEffect(() => {
    if (!selectedBank) return;

    setCurrentName(selectedBank?.value);
    setCurrentId(selectedBank?.id);
  }, [selectedBank]);
  return (
    <AuthLayout>
      <div className={s.top}>
        <div className={s.balances}>
          <AccountSelector setBank={setSelectedBank} selectedBankName={currentName} isPage={!!id} />
          <BalanceDetails id={currentId || ''} name={currentName || ''} />
        </div>
      </div>

      <div className={s.bottom}>
        <MessageList initialMessages={[]} />
      </div>
    </AuthLayout>
  );
};
