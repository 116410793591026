import React, { useState, useEffect } from 'react';
import { useSocketContext } from '../../context/socket-context';
import s from './index.module.scss';
import ObjectView from './json-view';

export interface Message {
  id: number;
  content: any;
  isExpanded: boolean;
}

interface MessageListProps {
  initialMessages?: Message[];
}

const MessageList: React.FC<MessageListProps> = ({ initialMessages = [] }) => {
  const [messages, setMessages] = useState<Message[]>(initialMessages);
  const { SocketState } = useSocketContext();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (!SocketState?.lastMessage) return;
    setCount((v) => v + 1);
    const message: Message = {
      content: SocketState.lastMessage,
      isExpanded: false,
      id: count,
    };
    setMessages((prevMessages) => [message, ...prevMessages.slice(0, 49)]);
  }, [SocketState]);

  const toggleExpand = (id: number) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) => (message.id === id ? { ...message, isExpanded: !message.isExpanded } : message)),
    );
  };

  return (
    <div className={s.root}>
      {messages &&
        messages.map((message) => (
          <div className={s.root__item} key={message.id} onClick={() => toggleExpand(message.id)}>
            <div className={s.info}>
              <b>{message.content?.transactionType?.toUpperCase()}</b>
              <i>{message.content?.transactionId}</i>
              <span>{message.content?.status}</span>
            </div>
            {message.isExpanded && (
              <div style={{ width: '100%', overflow: 'hidden', marginTop: '10px' }}>
                <ObjectView object={message.content} />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default MessageList;
