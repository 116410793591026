import React from 'react';

type NestedObject = {
  [key: string]: string | number | boolean | NestedObject | NestedArray;
};

type NestedArray = (string | number | boolean | NestedObject)[];

interface ObjectViewProps {
  object: NestedObject | any;
}

const ObjectView: React.FC<ObjectViewProps> = ({ object }) => {
  const renderObject = (obj: NestedObject, prefix = ''): any => {
    const content = (
      <div style={{ marginLeft: '20px' }}>
        {Object.keys(obj).map((key) => {
          const newKey = prefix ? `${prefix}.${key}` : key;
          if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Array)) {
            return renderObject(obj[key] as NestedObject, newKey);
          } else if (obj[key] instanceof Array) {
            return renderArray(obj[key] as NestedArray, newKey);
          }
          return (
            <div key={newKey}>
              <strong>{key}:</strong> {obj[key].toString()}
            </div>
          );
        })}
      </div>
    );

    if (prefix) {
      return (
        <div key={prefix}>
          <strong>{prefix}:</strong> {'{'}
          {content}
          {'}'}
        </div>
      );
    }
    return content;
  };

  const renderArray = (arr: NestedArray, prefix: string): any => {
    return (
      <div key={prefix}>
        <strong>{prefix}:</strong> {'['}
        <div style={{ marginLeft: '20px' }}>
          {arr.map((value, index) => {
            const newKey = `${prefix}[${index}]`;
            if (typeof value === 'object' && value !== null && !(value instanceof Array)) {
              return renderObject(value as NestedObject, newKey);
            } else if (value instanceof Array) {
              return renderArray(value, newKey);
            }
            return <div key={newKey}>{value.toString()}</div>;
          })}
        </div>
        {']'}
      </div>
    );
  };

  return (
    <div>
      {/*{"{"}*/}
      {renderObject(object)}
      {/*{"}"}*/}
    </div>
  );
};

export default ObjectView;
