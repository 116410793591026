import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

interface AuthContextState {
  isAuthorized: boolean;
  setIsAuthorized?: (isAuthorized: boolean) => void;
}

const defaultState: AuthContextState = {
  isAuthorized: false,
};
const AuthContext = createContext<AuthContextState>(defaultState);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  return <AuthContext.Provider value={{ isAuthorized, setIsAuthorized }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
